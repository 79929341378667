import React from "react"
import styled from "styled-components"

import { usePoints } from "../../hooks"
import Sun from "../../images/planets/sun.png"

const StyledSunCounter = styled.div`
  display: grid;
  justify-items: center;
  z-index: 10;
  gap: 5px;

  .sun {
    height: 100%;
    width: 100%;
  }
  .stars {
    color: ${({ theme }) => theme.colors.cta.main};
    text-align: center;
    text-shadow: 0 0.3em 0.3em black;
  }
  .sun-text {
    color: white;
    text-align: center;
    text-shadow: 0 0.3em 0.3em black;
  }
`

const StarsCountSunDisplay = () => {
  const { stars } = usePoints()
  const sun = Sun

  return (
    <StyledSunCounter>
      <img className="sun" src={sun} alt="Sun" />
      <span className="stars">{stars}</span>
      <span className="sun-text">Let`s spell</span>
    </StyledSunCounter>
  )
}

export default StarsCountSunDisplay
