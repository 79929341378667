// Basic libraries
import React, { Component } from "react"
import PropTypes from "prop-types"
import { Link, navigate } from "gatsby"

// Used components
import Navigation from "../../../../components/navigation"
import Content from "../../../../components/content"
import Seo from "../../../../components/seo"
import CurrentUser from "../../../../components/CurrentUser"
import StarsCountSun from "../../../../components/StarsCountSun"

// Images & Styling
import { BackButton } from "../../../../styles/buttons"
import ArrowIcon from "../../../../lib/icons/Arrow"
import { SpaceLayout } from "../../../../styles/common"
import Saturn from "../../../../images/planets/saturn.png"
import Sun from "../../../../images/planets/sun.png"
import Venus from "../../../../images/planets/venus.png"
import Earth from "../../../../images/planets/earth.png"
import Mars from "../../../../images/planets/mars.png"
import Moon from "../../../../images/planets/moon.png"
import Mercury from "../../../../images/planets/mercury.png"
import Sparkle from "react-sparkle"

// Fetching data
import graphqlClient from "../../../../services/graphql-client"
import {
  isJWTExpired,
  updateCurrentStudentData,
} from "../../../../services/helpers"

// Browser check
const isBrowser = typeof window !== "undefined"

// Token Access
const jwt = {
  jwtRequ: isBrowser ? window.localStorage.getItem("jwt_student") : null,
  checkPermission() {
    if ((!this.jwtRequ || isJWTExpired(this.jwtRequ)) && isBrowser)
      navigate("./")
    else if (this.jwtRequ)
      graphqlClient.setHeader("authorization", `Bearer ${this.jwtRequ}`)
  },
}

// component
class SpacePage extends Component {
  constructor() {
    super()
    this.state = {
      window: {
        width: 0,
        height: 0,
      },
    }
    this.handleResize = this.handleResize.bind(this)
  }
  // Fehlerschwerpunkt: 3 größten Zahlen in mistakeprognosis --> auf Fehlerkategorie mappen...

  handleResize = () => {
    this.setState({
      window: {
        width: window.innerWidth,
        height: window.innerHeight,
      },
    })
  }

  checkStudentData = async () => {
    try {
      const updateSuccessResult = await updateCurrentStudentData()
      console.log("update success:", updateSuccessResult)

      if (!updateSuccessResult.success && isBrowser) {
        navigate("/student", { replace: true })
      }
    } catch (error) {
      console.error(error)
      if (isBrowser) navigate("/student", { replace: true })
    }
  }

  componentWillMount() {
    // jwt.checkPermission()
    this.checkStudentData()
  }

  componentDidMount() {
    if (isBrowser) {
      this.setState(
        {
          window: {
            width: window.innerWidth,
            height: window.innerHeight,
          },
        },
        () => window.addEventListener("resize", this.handleResize)
      )
    }
  }

  componentWillUnmount() {
    window.addEventListener("resize", this.handleResize)
  }

  render() {
    const windowHeight = this.state.window.height
    const saturn = Saturn
    const sun = Sun
    const venus = Venus
    const earth = Earth
    const mars = Mars
    const moon = Moon
    const mercury = Mercury

    return (
      <div>
        <Seo title="My Space" />
        <Navigation>
          <BackButton as={Link} to="/student/profile" alt="Back to profile">
            <ArrowIcon />
          </BackButton>
          <CurrentUser />
        </Navigation>
        <Content height={windowHeight}>
          <SpaceLayout>
            <Sparkle
              color={"orange"}
              minsize={5}
              maxSize={15}
              fadeOutSpeed={85}
              overflowPx={0}
              count={25}
            />
            <section className="dashboard-section">
              <img src={saturn} alt="Dashboard" />
              <h2>View Learning achievements!</h2>
            </section>
            <section className="exercise-section">
              <Link to="/exercise">
                <StarsCountSun />
              </Link>
            </section>
            <section className="memory-section">
              <Link to="/student/profile/space/memory">
                <img src={venus} alt="Memory" />
              </Link>
              <h2>Play Memory!</h2>
            </section>
            <section className="earth-section">
              <Link to="/student/profile/space/word_relation">
                <img src={earth} alt="Earth" />
              </Link>
              <h2>Play Word Relation!</h2>
            </section>
            <section className="mars-section">
              <Link to="/student/profile/space/racer">
                <img src={mars} alt="Mars" />
              </Link>
              <h2>Play Racer!</h2>
            </section>
            <section className="moon-section">
              <Link to="/student/profile/space/ice_skater">
                <img src={moon} alt="Moon" />
              </Link>
              <h2>Play Ice Skater!</h2>
            </section>
            <section className="mercury-section">
              <img src={mercury} alt="Mercury" />
              <h2>Play ...!</h2>
            </section>
          </SpaceLayout>
        </Content>
      </div>
    )
  }
}
// component-end

// PropTypes
SpacePage.propTypes = {
  window: PropTypes.shape({
    width: PropTypes.number,
    height: PropTypes.number,
  }).isRequired,
}

export default SpacePage
